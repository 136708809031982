// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { axiosInstance } from "../../controller/axiosInterceptor";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyDshhWspHGeNDYLNtw3FpYI14fXzxaDzh8",
//   authDomain: "lma-web-b6fe7.firebaseapp.com",
//   projectId: "lma-web-b6fe7",
//   storageBucket: "lma-web-b6fe7.appspot.com",
//   messagingSenderId: "1029689611284",
//   appId: "1:1029689611284:web:5e2af44093867e8a140301"
// };

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "lma-dev.firebaseapp.com",
  projectId: "lma-dev",
  storageBucket: "lma-dev.appspot.com",
  messagingSenderId: "966632526561",
  appId: "1:966632526561:web:ee5fa2d906652406d95da0",
  measurementId: "G-CDG9JM4YTF"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

const getFCMToken = () => {
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      // console.log("Notification User Permission Granted.")
      getToken(messaging, { vapidKey: process.env.REACT_APP_VAPID_KEY })
        .then((token) => {
          if (token) {
            saveToken(token);
          } else {
            // console.log("Token not found", token);
          }
        })
        .catch((err) => {
          console.log("An error occurred while retrieving token. ", err);
        });
    } else {
      console.log("User Permission Denied.");
    }
  });
};

const saveToken = (token) => {
  axiosInstance
    .post("business/update/notification-status", {
      isNotification: true,
      fcmToken: token,
    })
    .then((res) => {
      // console.log("token saved", res.data);
    })
    .catch((err) => {
      console.log("error while saving token", err);
    });
};

export { getFCMToken, onMessage, messaging };
