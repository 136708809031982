import React, { useState } from "react";
import { Button, Modal } from "antd";
import { axiosInstance } from "../../../controller/axiosInterceptor";
import "./modals.scss";
import { message } from "antd";
import { useNavigate } from "react-router-dom";

export default function DeclineAppointmentModal(props) {
  const { id } = props;
  const navigate = useNavigate()
  const acceptorDeclineHandler = async (
    e,
    id,
    isapproved,
    startAppointment,
    closeAppointment
  ) => {
    const payload = {
      appointmentId: id,
      isApproved: isapproved,
      //  for acccepting true for decline as false
      startAppointment,
      // start appointment true fro start oppoinment
      closeAppointment,
    };
    try {
      const setAppointmnet = await axiosInstance.post(
        "/business/changeAppointmentStatus",
        payload
      );
      if (setAppointmnet.status == 200) {
        navigate("/business-provider/appointments-dashboard", {
          state: { statusTab: "Pending" },
        });
      }
    } catch (error) {
      message.error(error.message || error?.data?.error?.message);
    }
  };
  return (
    <Modal
      centered
      width={576}
      header={null}
      footer={null}
      open={props.visible}
      onCancel={props.onClose}
      onOk={props.onClose}
      className="common-modal decline-modal settings-service-modal"
      closable
      closeIcon={<img alt={""} src={"/assets/icons/close-icon.svg"} />}
    >
      <div className="row">
        <div className="col-12 crossImage common-div-modal text-center">
          <img alt={""} src={"/assets/icons/decline-info-icon.svg"} />
          <h3>{props.headingText}</h3>
          <ul className="mb-0 list-inline">
            <li>
              <Button
                className="no-gradient button-common"
                onClick={props.onClose}
              >
                No
              </Button>
            </li>
            <li>
              <Button
                style={{ marginLeft: "3px" }}
                className="bg-transparent p-0 shadow-none confirm-logout-btn"
                onClick={(e) => {
                  acceptorDeclineHandler(e, id, false);
                  props.onClose(e);
                }}
              >
                Yes
              </Button>
            </li>
          </ul>
        </div>
      </div>
    </Modal>
  );
}
