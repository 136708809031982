import React from "react";
import { Input, Form, Button, message } from "antd";
import { axiosInstance } from "../../../controller/axiosInterceptor";
import { useNavigate } from "react-router-dom";

const ChangePassword = () => {
  const navigate = useNavigate();

  const onFinish = (values) => {
    const params = {
      password: values.password,
      newPassword: values.newPassword
    };
    axiosInstance
      .put("/business/changePassword", params)
      .then((res) => {
        message.success("Password has been changed successfully");
        setInterval(() => {
          navigate("/business-provider/dashboard");
        }, 1500);
      })
      .catch((err) => {
        message.error(err.data?.error?.message);
      })
  };

  return (
    <>
      <div className="col-12 common-form-div">
        <Form
          name="changepassword"
          onFinish={onFinish}
          initialValues={{ remember: true }}
          layout="vertical"
        >
          <div className="setting-card row">
            <div className="col-12">
              <h3 className="heading edit-services-heading pb-3">
                Change Password
              </h3>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6">
                  <div className="row">
                    <div className="col-12">
                      <Form.Item
                        className="position-relative"
                        name={"password"}
                        rules={[
                          {
                            required: true,
                            message: "Please enter the old password",
                          },
                        ]}
                      >
                        <Input.Password
                          className="change-password-input"
                          placeholder="Type old Password"
                          maxLength={12}
                          prefix={
                            <div className="icon-beige-div">
                              <img
                                src="assets/icons/passwordIcon.svg"
                                alt="lock"
                                className="lock-icon"
                              />
                            </div>
                          }
                          iconRender={(visible) =>
                            visible ? (
                              <img
                                src="assets/icons/eye-open.svg"
                                alt="show"
                                className="eye-icon"
                                style={{ cursor: "pointer" }}
                              />
                            ) : (
                              <img
                                src="assets/icons/eye-closed.svg"
                                alt="hide"
                                className="eye-icon"
                                style={{ cursor: "pointer" }}
                              />
                            )
                          }
                          size="large"
                        />
                      </Form.Item>
                    </div>
                    <div className="col-12">
                      <Form.Item
                        className="position-relative"
                        name={"newPassword"}
                        rules={[
                          {
                            required: true,
                            message: "Please enter the new password",
                          },

                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              const isContainsUppercase = /^(?=.*[A-Z]).*$/;
                              const isContainsNumber = /^(?=.*[0-9]).*$/;
                              const isContainsSymbol =
                                /^(?=.*[~`!@#$%^&*()--+={}|\\:;"'<>,.?/_₹]).*$/;
                              if (value?.length < 8) {
                                return Promise.reject(
                                  new Error(
                                    "Must contain minimum of 8 characters."
                                  )
                                );
                              }
                              if (
                                !isContainsNumber.test(value) ||
                                !isContainsSymbol.test(value) ||
                                !isContainsUppercase.test(value)
                              ) {
                                return Promise.reject(
                                  new Error(
                                    "Atleast one uppercase, lowercase, number(0-9), special character."
                                  )
                                );
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <Input.Password
                          maxLength={12}
                          className="change-password-input"
                          prefix={
                            <div className="icon-beige-div">
                              <img
                                src="assets/icons/passwordIcon.svg"
                                alt="lock"
                                className="lock-icon"
                              />
                            </div>
                          }
                          iconRender={(visible) =>
                            visible ? (
                              <img
                                src="assets/icons/eye-open.svg"
                                alt="show"
                                className="eye-icon"
                                style={{ cursor: "pointer" }}
                              />
                            ) : (
                              <img
                                src="assets/icons/eye-closed.svg"
                                alt="hide"
                                className="eye-icon"
                                style={{ cursor: "pointer" }}
                              />
                            )
                          }
                          size="large"
                          placeholder="Type new Password"
                        />
                      </Form.Item>
                    </div>
                    <div className="col-12">
                      <Form.Item
                        name={"retypeNewPassword"}
                        rules={[
                          {
                            required: true,
                            message: "Please confirm the password",
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value ||
                                getFieldValue("newPassword") === value
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error(
                                  "Password should match with confirm password"
                                )
                              );
                            },
                          }),
                        ]}
                      >
                        <Input.Password
                          maxLength={12}
                          className="change-password-input"
                          prefix={
                            <div className="icon-beige-div">
                              <img
                                src="assets/icons/passwordIcon.svg"
                                alt="lock"
                                className="lock-icon"
                              />
                            </div>
                          }
                          iconRender={(visible) =>
                            visible ? (
                              <img
                                src="assets/icons/eye-open.svg"
                                alt="show"
                                className="eye-icon"
                                style={{ cursor: "pointer" }}
                              />
                            ) : (
                              <img
                                src="assets/icons/eye-closed.svg"
                                alt="hide"
                                className="eye-icon"
                                style={{ cursor: "pointer" }}
                              />
                            )
                          }
                          size="large"
                          placeholder="Retype new Password"
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="btn-container">
            <Button
              type="primary"
              htmlType="submit"
         
            >
              Save
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default ChangePassword;
