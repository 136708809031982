import { Button, Modal } from "antd";
import "./modals.scss";
import { useNavigate } from "react-router-dom";

export default function ApproveAppointment(props) {
  const { visible, onClose } = props;
  const navigate = useNavigate()

  const closeModal = () => {
    localStorage.clear()
    onClose()
    navigate('/');
  }

  return (
    <Modal
      centered
      width={576}
      header={null}
      footer={null}
      open={visible}
      onCancel={onClose}
      onOk={onClose}
      className="common-modal decline-modal settings-service-modal"
      closable
      closeIcon={<img alt={""} src={"/assets/icons/close-icon.svg"} />}
    >
      <div className="row">
        <div className="col-12 crossImage common-div-modal text-center">
          <img alt={""} src={"/assets/images/approve-img.svg"} />
          <h3>Please wait until your account is approved</h3>
          <ul className="mb-0 list-inline">
            <li>
              <Button
                className="no-gradient button-common w-50"
                onClick={closeModal}
              >
                Done
              </Button>
            </li>
          </ul>
        </div>
      </div>
    </Modal>
  );
}
