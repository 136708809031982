import React, { useEffect, useState } from "react";
import { Input, Form, Button, message } from "antd";
import {
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import StripeCardElements from "../payment/StripeCardElements";
import { axiosInstance } from "../../../controller/axiosInterceptor";
import dayjs from "dayjs";
import SpinWrapper from "../../../common/Loader/SpinWrapper";

const UpdatePaymentInformation = ({ bankDetails, debitCardDetails, address }) => {
  const [creditCardData, setCreditCardData] = useState({
    name: "",
    cardNumber: false,
    cardCvc: false,
    cardExpiry: false,
  });

  const [loading, setLoading] = useState();

  const stripe = useStripe();
  const elements = useElements();
  const [form] = Form.useForm();

  useEffect(() => {
    if (bankDetails || debitCardDetails) fetchData();
  }, [bankDetails, debitCardDetails]);

  const fetchData = async () => {
    try {
      const cardDetail = await axiosInstance.get("/businessPayment/getCard");
      if (
        cardDetail.data?.status === 200 &&
        cardDetail.data.output?.length > 0
      ) {
        setCreditCardData({
          name: cardDetail.data.output[0]?.name,
          cardNumber: cardDetail.data.output[0]?.last4,
          cardCvc: cardDetail.data.output[0]?.cardCvc,
          cardExpiry:
            cardDetail.data.output[0]?.exp_month > 10 ? `${cardDetail.data.output[0]?.exp_month}` + "/" + `${dayjs(cardDetail.data.output[0]?.exp_year).format("YY")}`
              : `0${cardDetail.data.output[0]?.exp_month}` + "/" + `${dayjs(cardDetail.data.output[0]?.exp_year).format("YY")}`,
        });
      }
    } catch (error) {
      message.error(error.data?.error?.message);
    }

    if (bankDetails) {
      setLoading(true);
      form.setFieldValue("bank_name", bankDetails.name);
      form.setFieldValue("routing_number", bankDetails.swiftCode);
      form.setFieldValue("account_number", bankDetails.actNumber);
      setLoading(false);
    }

    if (debitCardDetails) {
      setLoading(true);
      const expMonth = debitCardDetails?.expMonth >= 10 ? debitCardDetails?.expMonth : `0${debitCardDetails?.expMonth}`
      const expYear = debitCardDetails.expYear?.toString()?.split('')?.slice(2, 5)?.join('')
      form.setFieldValue("cardholder_name", debitCardDetails.name || 'XXXXXX');
      form.setFieldValue("card_number", `XXXX XXXX XXXX ${debitCardDetails.cardNumber}`);
      form.setFieldValue("expiry_date", `${expMonth}/${expYear}`);
      form.setFieldValue("card_cvv", debitCardDetails.cardCvv || 'XXX');
      setLoading(false);
    }
  };

  const handleSave = async () => {
    try {
      await updatePaymentInfo();
    } catch (error) {
      message.error("Error saving data:");
    }
  };

  const updatePaymentInfo = async (event) => {
    if (elements == null) {
      return;
    }

    setLoading(true);

    if (
      form.getFieldValue("bank_name") &&
      form.getFieldValue("account_number") &&
      form.getFieldValue("routing_number")
    ) {
      try {
        const token = await stripe?.createToken("bank_account", {
          bank_account: {
            country: "US",
            currency: "usd",
            // swift_code: 'AIVVCATT',
            bank_name: form.getFieldValue("bank_name"),
            account_number: form.getFieldValue("account_number"),
            routing_number: form.getFieldValue("routing_number"),
          },
        });

        if (token.token) {
          const payload = {
            bankDetails: {
              token: token.token.id,
              name: form.getFieldValue("bank_name"),
              actNumber: form.getFieldValue("account_number"),
              swiftCode: form.getFieldValue("routing_number"),
            },
            tos_acceptance: {
              date: new Date().getTime().toString(),
              ip: "8.8.8.8",
            },
          };

          axiosInstance
            .put("/business/updateProfile", payload)
            .then(() => {
              message.success("Payment details updated successfully..");
            })
            .catch(() => message.error("Something went wrong."));
        }

        if (token.error) message.error(token.error?.message);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    }

    if (
      creditCardData.cardCvc &&
      creditCardData.cardExpiry &&
      creditCardData.cardNumber &&
      creditCardData.name
    ) {
      try {
        if (elements == null) return;
        const { token, error } = await stripe.createToken(
          elements.getElement(CardNumberElement)
        );

        if (error) {
          message.error(error.message);
          setLoading(false);
        }

        const payload = {
          token: token?.id,
        };
        const res = await axiosInstance.post(
          "/businessPayment/addCard",
          payload
        );

        if (res.data?.status === 200) {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        message.error(error?.data?.error?.message);
      }
    }

    setLoading(false);
  };

  const handleChange = async (event) => {
    if (event.target) {
      setCreditCardData({
        ...creditCardData,
        name: event.target.value,
      });
    } else {
      const e = await event;
      setCreditCardData({
        ...creditCardData,
        [e.elementType]: e.complete,
      });
    }
  };

  return (
    <SpinWrapper loader={loading}>
      <div className="setting-card row">
        <div className="col-12 common-form-div settings-form-div payment-wrapper">
          <h3 className="heading">Update Payment Information</h3>
          {address?.country !== 'Canada' ? (
            <>
              <p className="paragraph">Banking Information</p>

              <Form name="updatePaymentInformation" form={form}>
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-6">
                    <Form.Item
                      name="bank_name"
                      rules={[
                        {
                          required: true,
                          message: "Please enter the bank name",
                        },
                      ]}
                    >
                      <Input placeholder="Enter bank name" />
                    </Form.Item>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6">
                    <Form.Item
                      name="routing_number"
                      rules={[
                        {
                          required: true,
                          message: "Please enter swift code",
                        },
                      ]}
                    >
                      <Input placeholder="Enter swift code" />
                    </Form.Item>
                  </div>
                  <div className="col-12">
                    <Form.Item
                      name="account_number"
                      rules={[
                        {
                          required: true,
                          message: "Please enter account number",
                        },
                      ]}
                    >
                      <Input placeholder="Enter Account number" />
                    </Form.Item>
                  </div>
                </div>
              </Form>
            </>
          ) : (
            <>
              <div className="col-12">
                <p className="paragraph">Debit Card Details</p>
              </div>
              <Form name="updatePaymentInformation" form={form}>
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-6">
                    <Form.Item
                      name="cardholder_name"
                      rules={[
                        {
                          required: true,
                          message: "Please enter cardholder’s name",
                        },
                      ]}
                    >
                      <Input readOnly placeholder="Cardholder’s name" />
                    </Form.Item>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6">
                    <Form.Item
                      name="card_number"
                      rules={[
                        {
                          required: true,
                          message: "Please enter the card number",
                        },
                      ]}
                    >
                      <Input readOnly placeholder="Card number" />
                    </Form.Item>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6">
                    <Form.Item
                      name="card_cvv"
                      rules={[
                        {
                          required: true,
                          message: "Please enter cvv",
                        },
                      ]}
                    >
                      <Input readOnly placeholder="CVV" />
                    </Form.Item>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6">
                    <Form.Item
                      name="expiry_date"
                      rules={[
                        {
                          required: true,
                          message: "Please enter expiry date",
                        },
                      ]}
                    >
                      <Input readOnly placeholder="Expiry date" />
                    </Form.Item>
                  </div>
                </div>
              </Form>
              {/* <StripeCardElements
                cardHoldersName={debitCardDetails?.name}
                handleChange={handleChange}
                handleSubmit={handleSave}
                cardData={debitCardDetails}
              /> */}
            </>
          )}

          <div className="col-12">
            <p className="paragraph">Credit Card Details</p>
          </div>
          <StripeCardElements
            cardHoldersName={creditCardData.name}
            handleChange={handleChange}
            handleSubmit={handleSave}
            cardData={creditCardData}
          />
        </div>
      </div>
      <div className="btn-container">
        <Button
          type="primary"
          htmlType="submit"
          className="save-button"
          onClick={handleSave}
        >
          Save
        </Button>
      </div>
    </SpinWrapper>
  );
};

export default UpdatePaymentInformation;
