import * as React from "react";
import { useSpring, animated } from "react-spring";
import "./style.scss";

const Drawer = ({ show, onClose, children }) => {
  const getLeftMargin = () => {
    if ((window.innerWidth * 0.62) < 600) {
      console.log('less', window.innerWidth * 0.62);
      return (window.innerWidth - 600) / 2;
    } else {
      return (window.innerWidth - (window.innerWidth * 0.62)) / 2;
    }
  };

  const props = useSpring({
    left: show ? getLeftMargin() : window.innerWidth,
    position: "fixed",
    top: "5vh",
    backgroundColor: show ? "#FFFFFF" : 'transparent',
    height: "90vh",
    width: "62%",
    minWidth: "600px",
    zIndex: 9999,
    borderRadius: "20px",
  });

  return (
    <animated.div style={props} className="drawer-wrapper tc-privacy">
      {show && (
        <div className="sliding-drawer">
          <div className="backdrop" />

          <div className="py-4">
            <div className="close-icon mt-2 me-2" onClick={onClose}>
              <img
                src="/assets/icons/close-icon-circle.svg"
                alt=""
                className="img-fluid"
              />
            </div>
            <div className="mt-4">{children}</div>
          </div>
        </div>
      )}
    </animated.div>
  );
};

export default Drawer;
