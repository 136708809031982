import React, { useEffect, useState } from "react";
import { Menu } from "antd";
import { useNavigate } from "react-router-dom";
import ModalComponent from "../modals/ModalComponent";
import "./sidebar.scss";

function Sidebar() {
  const navigate = useNavigate();
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const collapsed = false;
  const activeTab = localStorage.getItem("Tab");
  
  const onClick = (e) => {
    localStorage.setItem("Tab", e.key);
  };

  return (
    <div className="sidebar-custom">
      <div className="p-2 mt-2 mb-4 d-flex">
        <img
          src="/assets/images/app-logo.png"
          alt="logo"
          width={150}
          className="app-logo d-lg-ml-3"
          style={{marginLeft:"11.5%"}}
          
        />
      </div>
      <Menu
        className="ant-menu-dark"
        defaultSelectedKeys={["1"]}
        defaultOpenKeys={["sub1"]}
        theme="dark"
        inlineCollapsed={collapsed}
        style={{ background: "none",marginLeft:"7%" }}
        onClick={onClick}
      >
        <Menu.Item
          className={activeTab == "tmp_key-0" ? "active-menu-item" : ""}
          title="Dashboard"
          onClick={() => {
            navigate("/business-provider/dashboard", {
              state: { tabname: "Dashboard" }, // Pass tabname as state
            });
          }}
        >
          <img
            className={
              activeTab == "tmp_key-0" ? "img-fluid" : "img-fluid opacity-50"
            }
            src="/assets/icons/dashboard.svg"
            alt="dashboard"
          />
          <span>Dashboard</span>
        </Menu.Item>
        <Menu.Item
          className={activeTab == "tmp_key-1" ? "active-menu-item" : ""}
          title="Appointments"
          onClick={() => {
            navigate("/business-provider/appointments-dashboard", {
              state: { tabname: "Appointments" },
            });
          }}
        >
          <img
            className={
              activeTab == "tmp_key-1" ? "img-fluid" : "img-fluid opacity-50"
            }
            src="/assets/icons/appointments.svg"
            alt="appointments"
          />
          <span>Appointments</span>
        </Menu.Item>
        <Menu.Item
          className={activeTab === "tmp_key-2" ? "active-menu-item" : ""}
          title="Earnings"
          onClick={() => {
            navigate("/business-provider/earnings", {
              state: { tabname: "My Earnings" },
            });
          }}
        >
          <img
            className={
              activeTab == "tmp_key-2" ? "img-fluid" : "img-fluid opacity-50"
            }
            src="/assets/icons/total-earnings.svg"
            alt="earnings"
          />
          <span>My Earnings</span>
        </Menu.Item>
        <Menu.Item
          className={activeTab === "tmp_key-3" ? "active-menu-item" : ""}
          title="Availability"
          onClick={() => {
            navigate("/business-provider/availability", {
              state: { tabname: "My Availability" },
            });
          }}
        >
          <img
            className={
              activeTab == "tmp_key-3" ? "img-fluid" : "img-fluid opacity-50"
            }
            src="/assets/icons/calendar-white.svg"
            alt="availability"
          />
          <span>My Availability</span>
        </Menu.Item>
        <Menu.Item
          className={activeTab === "tmp_key-4" ? "active-menu-item" : ""}
          title=""
          onClick={() => {
            navigate("/help", {
              state: { tabname: "Help" },
            });
          }}
        >
          <img
            className={
              activeTab == "tmp_key-4" ? "img-fluid" : "img-fluid opacity-50"
            }
            src="/assets/icons/help.svg"
            alt="help"
          />
          <span>Help</span>
        </Menu.Item>
        <Menu.Item
          className={activeTab === "tmp_key-5" ? "active-menu-item" : ""}
          title="Contact"
          onClick={() => {
            navigate("/contact-us", {
              state: { tabname: "Contact Us" },
            });
          }}
        >
          <img
            className={
              activeTab == "tmp_key-5" ? "img-fluid" : "img-fluid opacity-50"
            }
            src="/assets/icons/contact-us.svg"
            alt="contact"
          />
          <span>Contact Us</span>
        </Menu.Item>
        <Menu.Item
          className={activeTab === "tmp_key-6" ? "active-menu-item mt-5" : "mt-5 "}
          title="logout"
          onClick={() => setOpenLogoutModal(true)}
        >
          <img
            className={
              activeTab == "tmp_key-6" ? "img-fluid mt-3" : "img-fluid opacity-50 mt-3  "
            }
            src="/assets/icons/logout.svg"
            alt="logout"
          />
          <span className="mt-3">Logout</span>
        </Menu.Item>
      </Menu>
      <ModalComponent
        heading="Are you sure you want to logout?"
        open={openLogoutModal}
        handleClose={() => setOpenLogoutModal(false)}
      />
    </div>
  );
}

export default Sidebar;
