import { Button, Modal, message } from "antd";
import "./modals.scss";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../../../controller/axiosInterceptor";

export default function ModalComponent(props) {
  const navigate = useNavigate();
  const { type, heading, open, handleClose } = props;
  const handleClick = async () => {
    if (type === "delete") {
      try {
        await axiosInstance.delete("/business/delete").then(() => {
          message.success("Account Deleted Successfully");
          localStorage.clear();
          navigate("/");
        });
      } catch (error) {
        message.error(error?.data?.error?.message || error?.message);
      }
    } else {
      localStorage.clear();
      navigate("/");
    }
  };
  return (
    <Modal
      centered
      width={500}
      header={null}
      footer={null}
      open={open}
      onCancel={handleClose}
      className="common-modal decline-modal logout-modal"
      closable
      closeIcon={
        <img alt={""} src={"/assets/icons/close-icon.svg"} width={45} />
      }
    >
      <div className="row" style={{ height: "fit-content" }}>
        <div className="col-12 crossImage common-div-modal text-center">
          <img
            alt={""}
            src={type === 'delete' ? "/assets/icons/deleteConfirm.svg" : "/assets/icons/logout-icon.svg"}
            className="img-fluid"
          />
          <h3>{heading}</h3>
          <ul className="mb-0 list-inline">
            <li>
              <Button
                className="w-50 no-gradient button-common"
                onClick={handleClose}
              >
                No
              </Button>
            </li>
            <li>
              <Button
                onClick={handleClick}
                className="bg-transparent p-0 shadow-none confirm-logout-btn"
              >
                Yes
              </Button>
            </li>
          </ul>
        </div>
      </div>
    </Modal>
  );
}