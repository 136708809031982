import React, { useEffect } from "react";
import { Form, Input, message } from "antd";
import "../../../../styles/form.scss";

const BankingInformation = ({ form1Ref, form, setDisabled ,onlyTextHandler}) => {
  useEffect(() => {
    if (form1Ref) {
      setDisabled(true)
    }
  }, [form1Ref]);

  const validateNumber = (_, value) => {
    if (!value || /^[0-9]+$/.test(value)) {
      if (/^[0-9]{0,32}$/.test(value)) {
        return Promise.resolve();
      }
      else return Promise.reject(new Error("Please input valid account number"));
    }
    return Promise.reject(new Error("Please input only numbers"));
  };

  const handleFormChange = () => {
    setDisabled(true)
 
    if (form.getFieldValue()) {
      setDisabled(false)
    }
    const hasErrors = !form.isFieldsTouched(true) || form.getFieldsError().filter(({ errors }) => console.log(errors) && errors.length).length > 0;
    setDisabled(hasErrors);
  }

  return (
    <>
      <div className="row">
        <div className="col-12 text-center steps-form-heading">
          <h5>Enter your banking information</h5>
        </div>
      </div>
      <div className="row">
        <div className="col-12 common-form-div form-create-profile">
          <Form
            name="basic"
            initialValues={{ remember: true }}
            autoComplete="off"
            form={form1Ref}
            onFieldsChange={handleFormChange}
          >
            <div className="row">
              <div className="col-12 col-sm-12 col-md-6">
                <Form.Item
                  className="position-relative"
                  label={false}
                  name="bank_name"
                  rules={[
                    {
                      required: true,
                      message: "Please input your bank name!",
                    },

                  ]}
                >
                  <Input placeholder="Enter bank name" maxLength={56} 
                  onKeyPress={onlyTextHandler}
                  
                  />
                </Form.Item>
              </div>
              <div className="col-12 col-sm-12 col-md-6">
                <Form.Item
                  className="position-relative"
                  label={false}
                  name="account_number"
                  rules={[
                    {
                      required: true,
                      message: "Please input your account number!",
                    },
                    {
                      validator: validateNumber,
                      message: "Please input valid account number!",
                    },
                  ]}
                >
                  <Input placeholder="Enter account number" type="number" maxLength={32} />
                </Form.Item>
              </div>
              <div className="col-12">
                <Form.Item
                  className="position-relative"
                  label={false}
                  name="routing_number"
                  rules={[
                    {
                      required: true,
                      message: "Please input your swift code!",
                    },
                  ]}
                >
                  <Input placeholder="Enter swift code" maxLength={12} />
                </Form.Item>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default BankingInformation;
