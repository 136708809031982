import React, { useEffect, useState } from "react";
import Layout from "../../../common/helper/layout";
import {
  CartesianGrid,
  XAxis,
  YAxis,
  ResponsiveContainer,
  AreaChart,
  Area,
  Tooltip,
} from "recharts";
import { Card, Spin, Select, message } from "antd";
import { axiosInstance } from "../../../controller/axiosInterceptor";
import "./dashboard.scss";
import { graphDayData, graphMonthData, monthData } from "./staticData";
import SpinWrapper from "../../../common/Loader/SpinWrapper";
import { useNavigate } from "react-router-dom";
import { subtractDate } from "../../../utils";

function ProviderDashboard() {
  const [isLoading, setIsLoading] = useState(false);
  const [activeGraph, setActiveGraph] = useState("12 Months");

  let [barGraphData, setBarGraphData] = useState({});
  let [position, setPosition] = useState({ x: 0, y: 0 });
  let [max, setMax] = useState(1000);
  let [count, setCount] = useState(0);

  const userName = localStorage.getItem("user");
  const name = JSON.parse(userName)?.firstName;

  const cardDetails = [
    {
      iconUrl: "/assets/icons/dashboardIcon.svg",
      amount: barGraphData?.pendingAppointment || 0,
      text: "Pending Appointments",
    },
    {
      iconUrl: "/assets/icons/dashboardIcon2.svg",
      amount: barGraphData?.upcomingAppointment || 0,
      text: "Upcoming Appointments",
    },
    {
      iconUrl: "/assets/icons/wallet.svg",
      amount: barGraphData?.totalEarnings || 0,
      text: "Total Earnings",
    },
  ];
  const navigate = useNavigate();

  const iconNavigate = (key) => {
    if (key == 0) {
      localStorage.setItem("Tab", "tmp_key-1");
      navigate("/business-provider/appointments-dashboard", {
        state: { tabname: "Appointments" },
      });
    } else if (key == 1) {
      localStorage.setItem("Tab", "tmp_key-1");

      navigate("/business-provider/appointments-dashboard", {
        state: { statusTab: "Upcoming", tabname: "Appointments" },
      });
    } else if (key == 2) {
      localStorage.setItem("Tab", "tmp_key-2");
      navigate("/business-provider/earnings", {
        state: { tabname: "My Earnings" },
      });
    }
  };

  const getGraphData = async (data) => {
    try {
      setIsLoading(true);
      const type = activeGraph.includes("Months") ? "month" : "days";
      let numberOf = 0;
      if (type === "month") {
        numberOf = activeGraph == "12 Months" ? 12 : 6;
      } else numberOf = activeGraph == "30 Days" ? 30 : 7;

      setCount(numberOf);

      const res = await axiosInstance.get(
        `/business/dashboard?type=${type}&number=${numberOf}`
      );
      setIsLoading(false);
      const month = new Date().getMonth();
      const day = new Date().getDate();
      const year = new Date().getFullYear();
      const dayInLastMonth = new Date(year, month, 0).getDate();

      if (res?.data?.status == 200) {
        let result = res.data.output?.graphdata?.reduce(
          (a, b) => Math.max(a, b.amount),
          -Infinity
        );
        setMax(result == -Infinity ? 1000 : result);
        let graphDataList = [];

        if (type === "month") {
          graphDataList = graphMonthData.map((item) => {
            const listItem = res.data.output?.graphdata?.find(
              (it) => it.name === item.name
            );
            if (listItem) {
              return { ...item, XAxis: item.name, amount: listItem.amount };
            } else return { ...item, XAxis: item.name };
          });

          if (numberOf === 12) {
            graphDataList = [
              ...graphDataList.slice(month + 1, 13),
              ...graphDataList.slice(0, month + 1),
            ];
          }
          if (numberOf === 6) {
            graphDataList =
              month >= 5
                ? graphDataList.slice(month - 5, month + 1)
                : [
                    ...graphDataList.slice(month + 1, 7),
                    ...graphDataList.slice(month - 5, month + 1),
                  ];
          }
        } else {
          graphDataList = graphDayData.map((item) => {
            const listItem = res.data.output?.graphdata?.find(
              (it) => it.name === item.name
            );
            if (listItem) {
              return { ...item, XAxis: item.name, amount: listItem.amount };
            } else return { ...item, XAxis: item.name };
          });

          if (numberOf === 30) {
            graphDataList =
              day >= 30
                ? graphDataList.slice(day - 30, day)
                : [
                    ...graphDataList.slice(
                      dayInLastMonth - (30 - day),
                      dayInLastMonth
                    ),
                    ...graphDataList.slice(0, day),
                  ];
          }
          if (numberOf === 7) {
            graphDataList = [0, 1, 2, 3, 4, 5, 6]
              .map((item) => {
                const d = new Date();
                const date = subtractDate(d, item, "day");
                const dateNumber = date.date();
                const XAxis = date.format("ddd");

                return {
                  name: dateNumber,
                  amount: graphDataList[dateNumber - 1]?.amount,
                  XAxis,
                };
              })
              .reverse();
          }
        }

        setBarGraphData({
          pendingAppointment: res.data.output?.pendingAppointment,
          upcomingAppointment: res.data.output?.upcomingAppointment,
          totalEarnings: res.data.output?.totalEarnings,
          graphdata: graphDataList,
        });
      }
    } catch (error) {
      setIsLoading(false);
      message.error(error?.data?.error?.message);
    }
  };

  useEffect(() => {
    setBarGraphData({ graphdata: graphMonthData });
  }, []);

  useEffect(() => {
    getGraphData();
  }, [activeGraph]);

  function getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
  }

  const CustomTooltip = ({ active, payload, label, ...rest }) => {
    if (active && payload?.length) {
      const yPosition = 220 - (payload[0]?.value / max) * 450;
      const xPosition = payload[0]?.value
        ? rest.coordinate?.x - 65
        : rest.coordinate?.x - 58;
      if (xPosition !== position.x) {
        setPosition({
          y: payload[0]?.value ? (yPosition < 0 ? -20 : yPosition) : 220,
          x: xPosition,
        });
      }
      const month = new Date().getMonth();
      const day = new Date().getDate();
      const monthName = [30, 7].includes(count)
        ? day < count
          ? payload[0]?.payload?.name > day
            ? monthData[month]
            : monthData[month + 1]
          : monthData[month + 1]
        : "";
      const thisMonth = getKeyByValue(monthData, payload[0]?.payload?.name);
      const year =
        thisMonth > new Date().getMonth() + 1
          ? new Date().getFullYear() - 1
          : new Date().getFullYear();

      return (
        <div className="custom-tooltip">
          <p className="label">
            {`${payload[0]?.payload?.name}${
              [30, 7].includes(count) ? ` ${monthName}` : ""
            }`}
            , {year}
          </p>
          <p className="label">
            $ {Number(payload[0]?.value).toLocaleString()}
          </p>
        </div>
      );
    }

    return null;
  };

  return (
    <Layout>
      {/* <center>
        <h3 style={{ marginTop: '30vh' }}>This page is under Development</h3>
      </center> */}
      <div className="dashboard-container row">
        <div className="col-12">
          <div className="row">
            <div className="col-12 dashboard-heading">
              <h5>Hey {name}! 👋</h5>
              <p>Here's what's happening with your store today</p>
            </div>
          </div>
          <div className="dashboard-row-card row mb-3 row-gap-3">
            {cardDetails.map((item, index) => {
              return (
                <div
                  key={index}
                  className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4"
                >
                  <Card className="custom-dashboard-card row mx-0">
                    <div className="col-12 position-relative">
                      <div className="card-icon-div">
                        <img
                          alt=""
                          src={item.iconUrl}
                          className="card-icon img-fluid"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            iconNavigate(index);
                          }}
                        />
                      </div>
                      <h4>
                        {index === 2
                          ? `$ ${Number(item?.amount).toFixed(2)}`
                          : item.amount}
                      </h4>
                      <p>{item?.text}</p>
                    </div>
                  </Card>
                </div>
              );
            })}
          </div>
          <Spin
            spinning={false}
            tip="loading earnings and your appointments..."
          >
            <Card
              className="chart-card-main custom-dashboard-card h-100"
              style={{ height: "auto" }}
            >
              <div className="chart-content mb-3 d-flex justify-content-between align-items-center">
                <h5 className="fw-bold">Earning Overview</h5>
                <div className="buttons d-flex justify-content-end align-items-center column-gap-2">
                  <button
                    className={activeGraph === "12 Months" ? "active" : ""}
                    onClick={() => setActiveGraph("12 Months")}
                  >
                    12 Months
                  </button>
                  <button
                    className={activeGraph === "6 Months" ? "active" : ""}
                    onClick={() => setActiveGraph("6 Months")}
                  >
                    6 Months
                  </button>
                  <button
                    className={activeGraph === "30 Days" ? "active" : ""}
                    onClick={() => setActiveGraph("30 Days")}
                  >
                    30 Days
                  </button>
                  <button
                    className={activeGraph === "7 Days" ? "active" : ""}
                    onClick={() => setActiveGraph("7 Days")}
                  >
                    7 Days
                  </button>
                </div>
              </div>
              <SpinWrapper loader={isLoading}>
                <div className="chart">
                  {barGraphData?.graphdata?.length > 0 && (
                    <ResponsiveContainer
                      minWidth={500}
                      width="100%"
                      height={330}
                      className="responsiveContainer h-100"
                    >
                      <AreaChart
                        data={barGraphData?.graphdata}
                        margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
                      >
                        <defs>
                          <linearGradient
                            id="colorUv"
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="1"
                          >
                            <stop
                              offset="0%"
                              stopColor="rgba(238, 242, 255, 0.40)"
                            />
                            <stop
                              offset="100%"
                              stopColor="rgba(238, 242, 255, 0.40)"
                            />
                          </linearGradient>
                        </defs>
                        <XAxis
                          padding={{ left: 4, right: 4 }}
                          dataKey="XAxis"
                          axisLine={false}
                          tickLine={false}
                          className="mb-3"
                        />
                        <YAxis
                          dx={-2}
                          tickCount={10}
                          axisLine={false}
                          tickLine={false}
                          tickFormatter={(item) =>
                            item == 0
                              ? 0
                              : item > 999
                              ? item > 99999
                                ? `${item / 1000}k`
                                : `${(item / 1000).toFixed(2)}k`
                              : item.toFixed(2)
                          }
                          domain={[0, Math.ceil(max / 1000) * 1000 || 1000]}
                        />
                        <CartesianGrid strokeDasharray="0" />
                        <Tooltip
                          content={<CustomTooltip />}
                          cursor={{ fill: "transparent" }}
                          position={position}
                          separator="false"
                        />
                        <Area
                          type="monotone"
                          dataKey="amount"
                          stroke="#112047"
                          fillOpacity={1}
                          fill="url(#colorUv)"
                          strokeWidth={2}
                          padding={{ bottom: 10 }}
                        />
                      </AreaChart>
                    </ResponsiveContainer>
                  )}
                </div>
              </SpinWrapper>
            </Card>
          </Spin>
        </div>
      </div>
    </Layout>
  );
}

export default React.memo(ProviderDashboard);
