import React from "react";
import PrivacyPolicyContent from "../../common/modals/auths/PrivacyPolicyContent";

const PrivacyPolicyPage = () => {

  return (
    <div className="tc-privacy">
      <PrivacyPolicyContent slider page />
    </div>
  );
};

export default PrivacyPolicyPage;
