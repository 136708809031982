import React, { useState } from "react";
import { Input, Form, Row, Col, Select } from "antd";
import DeleteServicePopup from "../DeleteServicePopup";
import UploadImage from "../profile-components/steps/UploadImage";

const { TextArea } = Input;

const AddMoreServices = ({
  serviceData,
  handleServiceChange,
  onDelete,
  edit,
  ind,
  options,
  setLoader,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleDelete = () => {
    setIsOpen(true);
  };

  const handleDeleteConfirmed = () => {
    setIsOpen(false);
    onDelete(serviceData.id);
  };

  const handleDeleteCancelled = () => {
    setIsOpen(false);
  };

  const handleInputChange = (value, name) => {
    const updatedServiceData = { ...serviceData, [name]: value };
    handleServiceChange(serviceData?._id || null, updatedServiceData, ind);
  };

  const handleUpdateImage = (name, value) => {
    const updatedServiceData = { ...serviceData, [name]: value };
    handleServiceChange(serviceData?._id || null, updatedServiceData, ind);
  };

  return (
    <div className="add-services-container">
      <Row>
        <Col span={24}>
          <div
            className="content position-relative mb-4"
          >
            <div className="p-0 d-flex justify-content-center align-items-center image-upload">
              <UploadImage
                handleUpdateServicesArr={handleUpdateImage}
                IS_SERVICES
                currentImage={serviceData?.imgUrl}
                setLoader={setLoader}
              />
            </div>

            <div className="contentright">
              <div className="input1 w-100">
                <Form.Item
                  name={edit ? `service_name_edit-${ind}` : "service_name"}
                  className="w-75 me-3"
                  rules={[
                    {
                      required: true,
                      message: "Please enter service title",
                    },
                  ]}
                >
                  <Input
                    className="input-field email-input service-type-input"
                    placeholder="Service title"
                    maxLength={36}
                    onChange={(e) => handleInputChange(e.target.value, "title")}
                  />
                </Form.Item>
                <Form.Item
                  className="position-relative w-50"
                  label={false}
                  name={edit ? `subCategories_edit-${ind}` : "subCategories"}
                  rules={[
                    {
                      required: true,
                      message: "Please select your Sub Category!",
                    },
                  ]}
                >
                  <Select
                    suffixIcon={
                      <img
                        src="/assets/icons/chevron-down-black.svg"
                        alt=""
                        className="img-fluid"
                      />
                    }
                    placeholder="Sub category"
                    onChange={(value) => handleInputChange(value, "subCatId")}
                    defaultValue={serviceData?.subCatName || null}
                    options={options}
                  />
                </Form.Item>
                <span onClick={handleDelete}>
                  <img
                    src="/assets/icons/deleteServiceIcon.svg"
                    alt="delete-service"
                    className="dlt-service-icon"
                  />
                </span>
              </div>
              <div className="input2 price-input w-100">
                <Form.Item
                  name={edit ? `service_fee_edit-${ind}` : "service_fee"}
                  className="col-12 mb-0"
                  rules={[
                    {
                      required: true,
                      message: "Please enter service fee",
                    },
                  ]}
                >
                  <Input
                    className="input-field service-type-fee"
                    placeholder="Service fee"
                    maxLength={4}
                    prefix="$"
                    onChange={(e) => handleInputChange(e.target.value, "price")}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="col-24">
            <Form.Item
              name={
                edit ? `service_description_edit-${ind}` : "service_description"
              }
              rules={[
                {
                  required: true,
                  message: "Please input your service description",
                },
              ]}
            >
              <TextArea
                rows={4}
                className="text-area"
                placeholder="Service description"
                defaultValue={serviceData.description || ""}
                onChange={(e) =>
                  handleInputChange(e.target.value, "description")
                }
              />
            </Form.Item>
          </div>
        </Col>
      </Row>

      <DeleteServicePopup
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onConfirm={handleDeleteConfirmed}
        onCancel={handleDeleteCancelled}
      />
    </div>
  );
};

export default AddMoreServices;
