import React, { useState } from "react";
import "./auths.scss";
import "../modal.scss";
import { Button, Form, Modal, message } from "antd";
import { Link } from "react-router-dom";
import { axiosInstance } from "../../../controller/axiosInterceptor";

const EmailSentModal = (props) => {
  const [counter, setCounter] = useState(0);
  const closeAllModel = () => {
    props.setemailsentvisibile(false);
    props.close(false);
  };
  const resendHandler = (values) => {
    const params = {
      email: props.email,
      userType: 2,
    };
    axiosInstance
      .post("/business/forgetPassword", params)
      .then((res) => {
        setCounter(2);
        // localStorage.setItem('accessToken',res.data.output.accessToken)
        // navigate('/profile-details')
        counter === 2 && message.success("Resend Sucessfully");
        // props.onClose();
      })
      .catch((err) => {
        message.error(err.data?.error?.message);
      });
  };

  return (
    <>
      <Modal
        width="495px"
        className="common-modal auths-modal-small auths-modal-email"
        centered
        title={false}
        open={props.visible}
        onOk={props.onClose}
        onCancel={props.onClose}
        footer={false}
        closeIcon={
          <img
            src="/assets/icons/close-icon-circle.svg"
            alt=""
            className="img-fluid"
          />
        }
      >
        <div className="row auths-main-row">
          <div className="col-12 auths-details h-auto">
            <div className="row">
              <div className="col-12 text-center auth-top-details">
                <Link to={"/"}>
                  <img
                    src="/assets/icons/email-sent-icon.png"
                    alt=""
                    className="img-fluid"
                  />
                </Link>
                <h5>Email has been sent {counter === 2 ? "again" : ""}.</h5>
                <p>
                  Please check your inbox. We have sent a new password to your
                  email address {counter === 2 ? "again" : ""}.
                </p>
              </div>
              <div className="col-12 common-form-div">
                <Form
                  name="basic"
                  initialValues={{ remember: true }}
                  autoComplete="off"
                >
                  <div className="row">
                    <div className="col-12 text-center">
                      <Form.Item className="mb-0">
                        <Button
                          className="common-btn submit-btn mt-0"
                          onClick={closeAllModel}
                        >
                          Sign In
                        </Button>
                      </Form.Item>
                    </div>
                    <div className="col-12 text-center bottom-text">
                      <p>
                        Didn’t receive the email?{" "}
                        <Link to={""} onClick={resendHandler}>
                          Resend
                        </Link>
                      </p>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EmailSentModal;
