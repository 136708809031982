import React from "react";
import { useNavigate } from "react-router-dom";
import "./auths.scss";
import "../modal.scss";

const TermsAndConditionsContent = ({ slider, page }) => {
  const navigate = useNavigate();

  return (
    <div className="row auths-main-row mx-0">
      <div
        className="col-12 col-sm-12 col-md-11 col-xl-11 mx-auto auths-details"
        style={{ marginTop: slider ? 0 : "-3rem" }}
      >
        {page && (
          <div className="back-button" onClick={() => navigate("/")}>
            <img src="/assets/icons/left-arrow.svg" alt="back" />
          </div>
        )}
        <div
          className={slider ? "row tc-conditions mt-3" : "row tc-conditions"}
        >
          <div className="col-12 text-center">
            <h5>LAST MINUTE APP - TERMS OF SERVICE </h5>
            {/* <p className="mb-0">Last Updated: July, 2023</p> */}
          </div>
        </div>
        <div className="row">
          <div
            className={`col-12 tc-conditions-inner ${
              page ? "full-height" : ""
            }`}
          >
            <h6>1. Introduction </h6>
            <p>
              These Terms of Use (the <b>“Terms”</b>) form a legally binding
              contract that regulates the relationship between LAST MINUTE APP
              LIMITED, a company incorporated under the laws of Alberta (
              <b>“We,” “Us,”</b> or the possessive <b>“Our” </b>as applicable)
              and users of the Last Minute App website (the <b>“Website”</b>),
              associated mobile app (the <b>“App”</b>).{" "}
            </p>
            <p>
              By visiting or using the Website or the App (collectively the{" "}
              <b>“Platform”</b>), You agree that You have read, comprehend, and
              agree to be bound by the Terms. Our privacy policy (the
              <b> “Privacy Policy”</b>) which can be viewed at <insert link />{" "}
              also forms part of and is integrated into these Terms by way of
              reference. If You do not agree to be governed by these Terms and
              the Privacy Policy, You may not access the Platform.
            </p>
            <p>
              If You are using the Platform on behalf of a company, entity, or
              organization (collectively, a <b>“Subscribing Organization”</b>)
              then You represent and warrant that You: (a) are an authorized
              agent or representative of that Subscribing Organization with the
              permission to bind such a Subscribing Organization to these Terms;
              (b) have read and comprehend these Terms, and (c) agree to these
              Terms on behalf of such Subscribing Organization.
            </p>
            <h6>2. Amendment of Terms </h6>
            <p>
              These Terms may be periodically amended by Us. We will provide
              notice of such changes by displaying them clearly on the Platform.
              By continuing to use the Platform after such amendments to the
              Terms take effect, You agree to abide by the Terms as amended. If
              You do not agree to be governed by the amended Terms, You must
              immediately terminate all use of the Platform and Services.
            </p>
            <h6>3. Use of Platform</h6>
            <p>
              The Platform allows individual clients(<b>“Clients”</b>)to view
              and book certain services (<b>“Third-Party Services”</b>) with
              certain third-party service providers (
              <b>“Third-Party Businesses” </b>) through functionality provided
              by the Platform. The Platform also allows Third-Party Service
              Providers to provide listings advertising their Third-Party
              Services and allowing Clients to book such Third-Party Services.
            </p>
            <p>
              <b>
                Third-Party Businesses are independent businesses that operate
                and provide their Third-Party Services independently from Us. We
                make no representation or warranty regarding any aspect of the
                Third-Party Business or the Third-Party Services including: (a)
                the quality of any Third-Party Services or the ability of the
                Third-Party Business to provide such Third-Party Services, (b)
                the accuracy of any listings provided by a Third-Party Business
                on the Platform, including any information regarding the
                Third-Party Business or its availability for appointments
                provided in such listing.
              </b>
            </p>
            <h6>4. Service Disputes</h6>
            <p>
              We do not provide any services directly to You and are not
              offering, soliciting, or otherwise agreeing to provide any such
              services through the Platform. Any contract for Third-Party
              Services shall be solely between the applicable Third-Party
              Business and the Client, and We shall not be a party to such
              contract. Without limiting the generality of the foregoing, we
              shall not be responsible for any refunds or payment disputes
              between a Third-Party Business and a Client and. Any disputes
              relating to the Third-Party Services shall be resolved by the
              Client and the Third-Party Business, with any full or partial
              refunds being provided directly by the Third-Party Business to the
              Client. The Platform does not provide any functionality through
              which refunds may be provided to Clients and We have no
              responsibility to assist the parties in communicating regarding
              such dispute or mediating or otherwise resolving such dispute. The
              Client and the Third-Party Business hereby release Us from any
              liability related to any dispute of any nature between the
              Third-Party Business and the Client, including in respect to the
              quality of the Third-Party Services.
            </p>
            <h6>5. Appointments</h6>
            <p>
              The Platform may provide Clients with functionality allowing them
              to review the availability of an register for appointments with
              Third-Party Businesses. You agree and acknowledge that We do not
              independently verify the availability of any appointments listed
              on the Platform, and we shall not be responsible for any
              inaccuracies or other problems related to any appointments listed
              on the platform, including any dishonored or double-booked
              appointments. We also do not represent and warrant that We will
              provide you appointment confirmations or appointment reminders in
              a timely manner or at all. We will not be liable to you in the
              event an appointment is missed due to the fact that an appointment
              confirmation or reminder was not transmitted to You.{" "}
            </p>
            <p>
              Certain Third-Party Businesses may set out a cancelation policy
              through the Platform. By setting out a cancelation policy through
              the Platform a Third-Party Business confirms that the policy was
              created in good faith and it shall abide by the terms of the
              policy.{" "}
            </p>
            <p>
              Clients agree that they will make best efforts to attend every
              appointment booked through the Platform as scheduled, and cancel
              the appointment in a timely manner in accordance with the
              corresponding Third-Party Business’s cancelation policy if they
              are unable to attend as scheduled. In the event You wish to cancel
              an appointment You agree to do so through functionality provided
              by the Platform rather than by contacting the Third-Party Business
              directly outside the Platform, and that such cancelation will not
              be valid unless performed through functionality provided by the
              Platform.{" "}
            </p>
            <p>
              You agree that a cancelation fee may apply based on the policy of
              the Third-Party Business, and agree to pay the same, or consent to
              the Third-Party Business retaining the same, as set out in the
              applicable cancelation policy of the Third-Party Business. Without
              limiting the generality of section 4, any disputes relating any
              cancelation fees shall be resolved between a Client and the
              Third-Party Business and we have no responsibility to assist the
              parties in communicating regarding such dispute or mediating or
              otherwise resolving such dispute, and the Client and Third Party
              Business release Us of any liability related to such a dispute. We
              will not be responsible for refunding any Client funds paid in
              respect to an appointment that was missed or cancelled in breach
              of the applicable Third-Party Business’s cancelation policy.{" "}
            </p>
            <h6>6. Fees</h6>
            <p>
              The fees for using the Platform (the <b>“Fees”</b>) shall be [NTD
              – please let me know if there’s further details on fees, should
              set them out here].{" "}
            </p>
            <h6>7. Accounts </h6>
            <p>
              In order to be able to use the Platform as a Client or Third-Party
              Business you must have a valid account in good standing (an{" "}
              <b>“Account”</b>). In order to create and maintain in good
              standing an Account you must provide us with accurate copies of
              any information requested by Us on account creation, as well as
              any additional information that may be requested by Us from time
              to time. You must also abide by these Terms and all applicable
              laws in your use of the Platform.{" "}
            </p>
            <p>
              You agree and acknowledge that any information provided to Us when
              creating an Account, or that may be subsequently provided by You,
              including any Client information, information regarding a
              Third-Party Business or the Third-Party Services, any contact
              information and payment information shall be accurate and free of
              misrepresentations. You agree and acknowledge that we shall not be
              liable to you in any manner for any damages suffered by you as a
              result of your failure to upload accurate information to the
              Platform.{" "}
            </p>
            <p>
              Accounts may not be shared or transferred, and only a single
              individual or Subscribing Organization may be associated with an
              Account. You are responsible for keeping your account secure, and
              not granting any third-party access to the same, including keeping
              your login credentials and password secure. We will not be liable
              in any manner to you for any unauthorized use of your Account.{" "}
            </p>
            <p>
              We may suspend access to or terminate access to your Account at
              any time, for any reason in our sole discretion, including but not
              limited to in the event that we determine you have breached your
              obligations under these Terms or You have engaged in any activity
              that violates the security or privacy procedures of our Platform.{" "}
            </p>
            <h6>8. Your Obligations</h6>
            <p>
              By using accessing the Platform, You agree: (a) not to re-publish,
              modify, copy or otherwise infringe the intellectual property
              rights connected with any resources provided on the Platform; (b)
              that the Platform will be used by You only in accordance with all
              Canadian and foreign, federal, state and local statutes, orders
              and regulations (<b>“Applicable Laws”</b>
              ), and under no circumstances will the Platform be used in a
              manner, or for any purpose that breaches Applicable Laws, or that
              may expose Us to civil or criminal liability; (c) not to submit
              any false or misleading information on the Platform, including in
              posting the same as part of any listings for a Third-Party
              Businesses; (d) not to hinder or attempt to interfere with the
              standard running of the Platform or any activities operated on the
              Platform. This includes taking any action that inflicts, or may
              inflict an disproportionate or unreasonably substantial load on
              the infrastructure of the Platform as determined by Us in our
              discretion; (e) not to gather or accumulate any information or
              data concerning the Platform or use any robot, scraper, spider or
              other automated means to access the Platform for any reason
              without our express written permission, and not to divulge any
              information regarding the Platform to third parties; (f) not to
              reverse engineer, decompile, disassemble or otherwise attempt to
              determine the source code of the Platform or use the Platform for
              competitive analysis or benchmarking purposes; (g) not to take any
              action that may damage, harm, or diminish in any way, Our
              reputation or Our public image; (h) not to use Platform for any
              conduct that could be interpreted as unlawful, abusive,
              threatening, stalking, tortuous, harmful, libelous, vulgar,
              defamatory, hateful, hostile, offensive, racially, obscene,
              ethnically or otherwise unacceptable or objectionable, including
              engaging in such conduct towards other users of the Platform; (i)
              not to upload any material to the Platform designed to interrupt
              or interfere with the Platform inclusive of, but not limited to
              viruses, spyware, worms, and malware; (j) not to infect the
              Platform with a virus or other computer code, files or programs
              designed to disturb, damage, destroy or limit the functionality of
              any computer hardware or software.{" "}
            </p>
            <h6>9. Payments</h6>
            <p>
              Payments may be made and funds may be added to Your Account using
              only the methods specified on our Platform. A reasonable
              processing period may elapse from the time a deposit is initiated
              through your payment method and the funds are available to use
              through your account.{" "}
            </p>
            <p>
              You agree and acknowledge that we may use third-party payment
              service processors such as Stripe to process payments, and that
              such third-party service providers may have separate terms of
              service and privacy policies in addition to ours. By making a
              payment through the Platform you agree to be bound by these
              additional terms of service and privacy policies.{" "}
            </p>
            <p>
              By making a payment through the Platform you represent and warrant
              that any payment information provided by You is accurate and that
              you are legally authorized to render such payment using the
              payment method used. You agree and acknowledge that We shall not
              be liable for any payments that do not complete because your
              credit card is not authorized to process the transaction for any
              reason or because you have failed to provide us with correct
              payment information.
            </p>
            <p>
              In the event we terminate your account any funds left in your
              account, less any applicable processing fees or any funds
              authorized to be used for a transaction, shall be returned to you
              at the same payment method you used to initially deposit funds
              into your account within ninety (90) business days of account
              termination.{" "}
            </p>
            <p>
              You agree and acknowledge that (a) We are not a bank and your
              Account is not a bank account; (b) the amounts in any account are
              not insured by any government agency; (c) we do not act as a
              trustee, fiduciary or escrow holder in respect of balances in your
              account; and (d) any amounts deposited in your account will not
              bear interest.
            </p>
            <p>
              You agree that we may, in our sole discretion, withhold funds
              deposited with Us in the event we have a reasonable suspicion that
              such funds are associated with money laundering, suspicious
              transactions, fraud, or other illegal activities, or we are
              otherwise required to withhold such funds by law.{" "}
            </p>
            <h6>10. Chargebacks </h6>
            <p>
              If you deposit funds through a method that provides you a right to
              claim a return of funds deposited with your deposit payment method
              provider (a <b>"Chargeback"</b>) you agree not to exercise such
              Chargeback rights other than for unauthorized use of the payment
              method or for a breach by us of these Terms which would result in
              you having a right to a refund of the deposited funds. In the
              event you engage in a Chargeback in breach of this provision We
              reserve the right to charge you fees and expenses we incur in
              connection with such Chargeback and any action undertaken to
              challenge the same. Without limiting our rights or remedies, if
              you do engage in a Chargeback in breach of the restrictions in
              this section 10, you are responsible for refunding the payment to
              us. We may, at our discretion, recover the amount by reducing your
              Account balance or debiting your deposit payment method for this
              amount.{" "}
            </p>
            <h6>11 . Submissions </h6>
            <p>
              By uploading material to the Platform (a <b>“Submission”</b>),
              either as a Client or a Third-Party Business, you hereby grant Us
              and our partners a worldwide, non-exclusive, sub-licensable,
              fully-paid, royalty-free license to use, modify, publicly perform,
              publicly display and distribute such Submissions in any manner as
              determined by Us. You also represent and warrant that you are the
              owner of all intellectual property rights in such Submission or
              have a license granting You the right to grant the rights set out
              herein, and that uploading or publishing such Submission shall not
              infringe on the intellectual property rights of any third party.{" "}
            </p>
            <h6>12. Third-Party Materials </h6>
            <p>
              The Platform may include links to third party websites, services,
              products, or content (<b>“Third-Party Materials”</b>), including
              but not limited to materials uploaded to the Platform by our
              Third-Party Service Providers. We do not own, regulate or license
              any such Third-Party Materials and We make no endorsements,
              representations, guarantees, or warranties with respect to any
              Third-Party Materials. If You click-through on any link posted on
              the Platform, You do so at Your own risk and acknowledge that We
              (including, but not limited to, our owners, directors, officers,
              senior managers, agents, and employees) shall not be liable in any
              manner for any harm (including emotional harm), injury, cost,
              damage, or loss You may undergo as a result of doing so.{" "}
            </p>
            <h6>13. Contact by Us </h6>
            <p>
              You agree that may contact You in respect to your Account or your
              activities on the Platform, including for example for the purpose
              of providing appointment confirmations, at the phone number, email
              address or other contact information provided by You when you
              created your Account, as well as through push notifications
              through our App. You acknowledge that there may be additional fees
              charged by you by your phone carrier for receiving any
              communications and that you will be solely responsible for these
              fees. You also agree and acknowledge that we may use third-party
              service providers, including automated phone or text message
              providers, to communicate with you and consent to receiving
              communications from these providers.{" "}
            </p>
            <h6>14. Intellectual Property Rights</h6>
            <p>
              The Platform and materials provided on the Platform, save for any
              user-submitted material, are Our intellectual property and We
              reserve all right to the same. Nothing in these Terms shall act as
              a license or assignment to such materials unless expressly
              specified by us. You may not copy, modify, create derivative works
              based upon, publicly display or otherwise commercially exploit the
              Platform or materials in any manner without our express written
              permission.{" "}
            </p>
            <h6>15. DMCA Compliance </h6>
            <p>
              We respect the intellectual property rights of others and will
              quickly respond to any claims of copyright infringement reported
              in relation to the Platform. If you find anything on the Platform
              that you believe infringes your copyright or the copyrights of a
              party you are authorized to represent, please report the alleged
              infringement under the Digital Millennium Copyright Act (DMCA). To
              do so, please provide our copyright agent (
              <b>“Copyright Agent”</b>) with the following information:{" "}
            </p>
            <ol type="a" className="listShow">
              <li data-icon="a">
                identification of the copyrighted work that you claim has been
                infringed;
              </li>
              <li>
                identification of the allegedly copyright infringing content our
                Platform that you desire to have removed or disabled;
              </li>
              <li>
                your contact information including name, address, email address,
                and phone number;{" "}
              </li>
              <li>
                a physical or digital signature of the true copyright holder or
                their authorized representative;{" "}
              </li>
              <li>
                a statement by you that you have a good faith belief that the
                use of the allegedly copyright infringing material is not
                authorized by the copyright owner, its agent, or the law;{" "}
              </li>
              <li>
                a statement by you that the information in the DMCA Notice is
                accurate, and under penalty of perjury, that you are the
                copyright owner or are authorized to act on behalf of the
                copyright owner.{" "}
              </li>
            </ol>
            <p>
              Our Copyright Agent may be reached at the following contact
              information:
            </p>
            <p>
              By mail:
              <br /> &lt;enter physical mailing address &gt;
            </p>
            <li></li>
            <p>
              By phone:
              <br />
              &lt;enter phone number&gt;
            </p>
            <p>
              By e-mail: <br />
              &lt;enter e-mail address&gt;.
            </p>
            <p>
              Please be aware that pursuant to copyright laws of some
              jurisdictions, a person who knowingly materially misrepresents
              that material or activity is infringing may be liable for damages.{" "}
            </p>
            <h6>16. Disclaimer of Warranties</h6>
            <p>
              YOUR USE OF THE PLATFORM AND/OR THE THIRD-PARTY SERVICES ARE AT
              YOUR OWN RISK. THE PLATFORM AND THE THIRD-PARTY SERVICES IN WHOLE
              AND IN PART (INCLUDING, WITHOUT LIMITATION, ALL CONTENT, AND USER
              MATERIALS), ARE PROVIDED, TRANSMITTED, DISTRIBUTED, AND MADE
              AVAILABLE ON AN "AS IS" “AS AVAILABLE” BASIS WITHOUT EXPRESS OR
              IMPLIED WARRANTIES OF ANY KIND, INCLUDING, WITHOUT LIMITATION,
              WARRANTIES OF TITLE, IMPLIED WARRANTIES OF MERCHANTABILITY OR
              FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT. WITHOUT
              LIMITING THE GENERALITY OF THE FOREGOING, WE MAKE NO WARRANTY: (A)
              THAT THE PLATFORM, WILL BE UNINTERRUPTED OR ERROR FREE; (B) THAT
              THE PLATFORM WILL BE FREE FROM VIRUSES OR OTHER HARMFUL
              COMPONENTS; (C) AS TO THE QUALITY, ACCURACY, COMPLETENESS AND
              VALIDITY OF ANY INFORMATION OR MATERIALS IN CONNECTION WITH THE
              PLATFORM; (D) THAT YOUR USE OF THE PLATFORM OR ANY THIRD-PARTY
              SERVICES WILL MEET YOUR REQUIREMENTS; OR (E) THAT TRANSMISSIONS
              THROUGH THE PLATFORM OF YOUR DATA WILL BE SECURE OR FREE FROM
              SPYWARE OR MALWARE.{" "}
            </p>
            <h6>17. Limitation of Liability</h6>
            <p>
              BY ACCESSING THE PLATFORM OR SERVICES YOU AGREE AND ACKNOWLEDGE
              THAT YOUR USE IS AT YOUR OWN RISK AND THAT WE, OUR SHAREOLDERS,
              DIRECTORS, EMPLOYEES, CONSULTANTS, AGENTS, AND THIRD PARTY
              BUSINESS PARTNERS (COLLECTIVELY <b>"RELEASED PARTIES"</b>) SHALL
              NOT BE LIABLE FOR ANY DIRECT, INCIDENTAL, CONSEQUENTIAL, INDIRECT,
              SPECIAL, OR PUNITIVE DAMAGES, OR ANY OTHER LOSSES, COSTS, OR
              EXPENSES OF ANY KIND (INCLUDING, WITHOUT LIMITATION, LOST PROFITS,
              LOSS OF DATA, LEGAL FEES, EXPERT FEES, COST OF PROCURING
              SUBSTITUTE SERVICES, LOST OPPORTUNITY, OR OTHER DISBURSEMENTS)
              WHICH MAY ARISE, DIRECTLY OR INDIRECTLY, THROUGH THE ACCESS TO,
              USE OF, RELIANCE ON ANY MATERIAL OR CONTENT ON THE PLATFORM OR
              THIRD-PARTY SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE
              POSSIBILITY OF SUCH DAMAGES.
            </p>
            <h6>18. Class Action and Jury Trial Waiver</h6>
            <p>
              UNLESS SUCH A RESTRICTION IS PROHIBITED BY LAW IN YOUR
              JURISDICTION OF RESIDENCE, YOU AGREE TO: (A) WAIVE ANY RIGHT TO
              COMMENCE OR PARTICIPATE IN ANY CLASS ACTION AGAINST THE RELEASED
              PARTIES AND WHERE APPLICABLE, AGREE TO OPT OUT OF ANY CLASS
              PROCEEDINGS AGAINST THE RELEASED PARTIES, INCLUDING THE US,
              RELATING TO THE SAME; AND (B) WAIVE ANY RIGHT TO HAVE ANY DISPUTE
              WITH THE RELEASED PARTIES RELATING TO THIS AGREEMENT RESOLVED
              THROUGH A JURY TRIAL.
            </p>
            <h6>19. Indemnity</h6>
            <p>
              By accessing the Platform and using the Services, You agree to
              indemnify and hold Us and our affiliated companies, officers,
              senior managers, directors, employees, partners and agents
              harmless from any demand or claim, including without limitation
              legal costs and reasonable attorneys' fees, made by any third
              party as a result of or arising out of Your conduct on this
              Platform, Your violation of these Terms or Your violation of any
              rights of any other party.
            </p>
            <h6>20. Age of Majority </h6>
            <p>
              This Platform is not intended for persons who are under the age of
              19 or the age of majority in their jurisdiction. By accessing or
              using the Platform, You represent and warrant that You are above
              the age of 19 and above the age of majority in Your jurisdiction.
              If You are under the age of 19 or the age of majority in Your
              jurisdiction You must immediately terminate all use of the
              Platform.
            </p>
            <h6>21. Applicable Law </h6>
            <p>
              You acknowledge and agree that these Terms along with Your use of
              the Platform and Services are governed by the laws of Alberta and
              the federal laws of Canada, without regard to conflict of law
              provisions. You hereby consent to the exclusive jurisdiction of
              the courts of Alberta for the resolution of any and all disputes
              concerning and involving these Terms. [NTD – you may want to
              consider having any disputes relating to these terms resolved by
              arbitration instead. Arbitration is common in such provisions can
              be quicker than the courts, but can be more expensive as well as
              the parties have to pay for the costs of the arbitrator. There
              have also been disputes recently regarding the applicability of
              such mandatory arbitration provisions recently. Please let me know
              if any questions or you would like to discuss.]
            </p>
            <h6>22. Severability</h6>
            <p>
              If any provision of these Terms, or the application of such
              provision to any individual or incident, will be held
              unenforceable, the remainder of these Terms, or the application of
              such provision to any individual or incident other than those to
              which it is held unenforceable, will not be influenced by it to
              the degree that doing so will not affect the primary objective of
              the Terms.
            </p>
            <h6>23. Entire Agreement</h6>
            <p>
              These Terms and the Privacy Policy contain the entire agreement
              between the parties relating to the subject matter thereto and
              supersede all previous agreements, negotiations, understandings
              and discussions between the parties, and there are no warranties,
              representations, conditions, covenants, or other terms other than
              those clearly contained in these Terms. These Terms may only be
              amended by Us in writing as is set out in section 2 of these
              Terms.{" "}
            </p>
            <h6>24. Waiver</h6>
            <p>
              No waiver of any of these Terms shall be inferred from or implied
              by anything done or omitted by Us, save for an express waiver in
              writing. No waiver, condoning, excusing or overlooking by Us of
              any default or breach of these Terms shall operate as a waiver of
              our rights under these Terms in respect of any continuing or
              subsequent default or breach of these Terms.
            </p>
            <div className="d-flex">
              <span className="pcHeader"> Effective Date:</span>{" "}
              <p> &nbsp;[February 09, 2024].</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditionsContent;
