import React, { useEffect, useState, useRef } from "react";
import { Rate, Progress } from "antd";
import "./rating.scss";
import dayjs from "dayjs";
import { axiosInstance } from "../../controller/axiosInterceptor";

const RatingsComp = (id) => {
  const [ratingInfo, setRatingInfo] = useState();
  const [loading, setLoading] = useState(true);
  const [ratingArr, setRatingArr] = useState([]);
  const [page, setPage] = useState(1);
  const listInnerRef = useRef();

  useEffect(() => {
    getRatings();
  }, [page]);

  const getRatings = async () => {
    const pagelimit = 10;
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        `business/rating?pageLimit=${pagelimit}&page=${page}`
      );
      if (response.data.output?.ratings) {
        setRatingArr([...ratingArr, ...response.data.output?.ratings?.list]);
        setRatingInfo(response.data.output);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;

      const addedScrollHeight = (scrollTop + clientHeight).toFixed(0);

      if (
        addedScrollHeight == scrollHeight ||
        addedScrollHeight == scrollHeight + 1 ||
        addedScrollHeight == scrollHeight - 1
      ) {
        if (ratingInfo?.ratings?.hasResult) {
          setPage(page + 1);
        }
      }
    }
  };

  const convertValueIntoHalf = (value) => {
    if(value && value*10 % 10 !== 0) {
      return Number(value.toString().split('')[0]) + .5
    }
    else return value;
  }

  return (
    <div className="ratingwrapper">
      {ratingInfo && (
        <>
          <div className="ratings">
            <div className="rleft ">
              <div className="rscore">
                <span>{ratingInfo?.average || 0}</span>/5
              </div>
              <div className="rhead">
                Total {ratingInfo?.ratingCount} Ratings
              </div>
              <div className="rrating">
                <Rate
                  allowHalf
                  value={convertValueIntoHalf(ratingInfo?.average)}
                  disabled={true}
                  style={{ color: "#FF9F29" }}
                />
              </div>
            </div>
            <div className="rright">
              <div className="rightinfo">
                {[1, 2, 3, 4, 5]?.map((item) => {
                  const ratingAvailable = ratingInfo?.ratingPercentage?.find(
                    (ratingP) => ratingP.rating === 6 - item
                  );
                  return (
                    <div className="progressbar">
                      <div className="ratingbar">
                        <div className="div1">{6 - item} star</div>
                        <div className="progressb">
                          <Progress
                            percent={
                              ratingAvailable ? ratingAvailable.percentage : 0
                            }
                            showInfo={false}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <h5 className="userrating-heading">User Ratings</h5>
          <div
            className="peopleratings"
            ref={listInnerRef}
            onScroll={handleScroll}
          >
            {ratingArr?.map((item) => {
              return (
                <div className="peoplerating " key={item?._id}>
                  <div className="singlerating">
                    <div className="ratinginfo">
                      <div className="ratingname">{item?.userName}</div>
                      <div className="rating">
                        Reviewed on{" "}
                        {dayjs(item?.createdAt).format("DD MMMM YYYY")}
                      </div>
                    </div>
                  </div>
                  <div className="ratingtime">
                    {item?.rating ? (
                      <Rate
                        allowHalf
                        disabled={true}
                        value={item?.rating}
                        style={{ color: "#FF9F29" }}
                      />
                    ) : (
                      <Rate
                        character={<img src="assets/icons/star.svg" alt="" />}
                        allowHalf
                        value={item?.rating}
                        disabled={true}
                        style={{ color: "#FF9F29" }}
                      />
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
      {!ratingInfo && !loading && (
        <div style={{ textAlign: "center", fontSize: '20px', fontWeight: '600', marginTop: '100px ' }}>No Ratings Available</div>
      )}
    </div>
  );
};

export default RatingsComp;
