import React, {useEffect, useState} from "react";
import "./header-footer.scss";
import {Link} from "react-router-dom";
import {Button} from "antd";
import SignUpModal from "../../modals/auths/SignUpModal";
import SignInModal from "../../modals/auths/SignInModal";

const CustomHeader = ({homeRef, contactRef, howRef, aboutRef, showModalSignup, showModalSignIn}) => {
    const [isActive, setIsActive] = useState(false);
    const [activeNavLink, setActiveNavLink] = useState('home');

    const handleToggleSidebar = () => {
        setIsActive((current) => !current);
    };


    useEffect(() => {
        window.addEventListener('scroll', isActiveClass);
        return () => {
            window.removeEventListener('scroll', isActiveClass);
        };
    });

    const isActiveClass = (e) => {
        const header = document.querySelector('.header-main');
        const scrollTop = window.scrollY;
        scrollTop >= 50 ? header.classList.add('is-active-header') : header.classList.remove('is-active-header');
    };

    const setActiveNav = (active, ref) => {
        const headerOffset = 120;
        const elementPosition = ref.current?.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
        window.scrollTo({
            top: offsetPosition, behavior: "smooth"
        });
        // ref?.current?.scrollIntoView({ behaviour: "smooth" })
        setActiveNavLink(active)
        setIsActive(false)
    }

    return (<>
            <div
                className={`position-fixed w-100 sidebar-main ${isActive ? "sidebar-toggle" : ""}`}
            >
                <Button onClick={handleToggleSidebar} className="sidebar-close-btn">
                    <img
                        src="/assets/icons/close-icon.svg"
                        alt=""
                        className="img-fluid"
                    />
                </Button>
                <div className="row">
                    <div className="col-12">
                        <ul className="mb-0 list-inline nav-list-ul">
                            <li>
                                <Link to={"#"} onClick={() => setActiveNav('home', homeRef)}
                                      className={activeNavLink == 'home' ? 'active' : ''}>Home</Link>
                            </li>
                            <li>
                                <Link to={"#"} onClick={() => setActiveNav('about', aboutRef)}
                                      className={activeNavLink == 'about' ? 'active' : ''}>About us</Link>
                            </li>
                            <li>
                                <Link to={"#"} onClick={() => setActiveNav('howItWorks', howRef)}
                                      className={activeNavLink == 'home' ? 'active' : ''}>How it works</Link>
                            </li>
                            <li>
                                <Link to={"#"} onClick={() => setActiveNav('contact', contactRef)}
                                      className={activeNavLink == 'contact' ? 'active' : ''}>Contact Us</Link>
                            </li>
                            {/*<li>*/}
                            {/*    <Link to={"#"} onClick={() => setActiveNav('faq', contactRef)}*/}
                            {/*          className={activeNavLink == 'faq' ? 'active' : ''}>Faq</Link>*/}
                            {/*</li>*/}
                            <li className="auth-btn-li list-inline-item">
                                <Button
                                    onClick={() => showModalSignIn(true)}
                                    className="common-btn sign-in-btn"
                                >
                                    Sign in
                                </Button>
                            </li>
                            <li className="list-inline-item">
                                <Button
                                    onClick={() => showModalSignup(true)}
                                    className="common-btn signup-btn"
                                >
                                    Sign up
                                </Button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="container-fluid header-main">
                <div className="container h-100">
                    <div className="row mx-0 h-100 align-items-center">
                        <div className="col-9 col-sm-6 col-md-2 col-lg-2 col-xl-2 logo-div">
                            <Link to={" "}>
                                <img
                                    src="/assets/images/logo-last-minute-app.png"
                                    alt=""
                                    className="img-fluid d-none d-sm-none d-md-block d-lg-block d-xl-block"
                                />
                                <img
                                    src="/assets/icons/lma-logo-mobile.png"
                                    alt=""
                                    className="img-fluid  d-block d-sm-block d-md-none d-lg-none d-xl-none"
                                />
                            </Link>
                        </div>
                        <div className="col-3 col-sm-6 col-md-10 col-lg-10 col-xl-10">
                            <Button
                                onClick={handleToggleSidebar}
                                className="menu-hamburger-btn"
                            >
                                <img
                                    src="/assets/icons/menu-hamburger-icon.svg"
                                    alt=""
                                    className="img-fluid"
                                />
                            </Button>
                            <ul className="mb-0 d-none d-sm-none d-md-block d-lg-block d-xl-block list-inline nav-list-ul">
                                <li className="list-inline-item">
                                    <Link to={"#"} onClick={() => setActiveNav('home', homeRef)}
                                          className={activeNavLink == 'home' ? 'active' : ''}>Home</Link>
                                </li>
                                <li className="list-inline-item">
                                    <Link to={"#"} onClick={() => setActiveNav('about', aboutRef)}
                                          className={activeNavLink == 'about' ? 'active' : ''}>About us</Link>
                                </li>
                                <li className="list-inline-item">
                                    <Link to={"#"} onClick={() => setActiveNav('howItWorks', howRef)}
                                          className={activeNavLink == 'howItWorks' ? 'active' : ''}>How it works</Link>
                                </li>
                                <li className="list-inline-item">
                                    <Link to={"#"} onClick={() => setActiveNav('contact', contactRef)}
                                          className={activeNavLink == 'contact' ? 'active' : ''}>Contact us</Link>
                                </li>
                                {/*<li className="list-inline-item">*/}
                                {/*    <Link to={"#"} onClick={() => setActiveNav('faq', contactRef)}*/}
                                {/*          className={activeNavLink == 'faq' ? 'active' : ''}>Faq</Link>*/}
                                {/*</li>*/}
                                <li className="list-inline-item auth-btn-li">
                                    <Button
                                        onClick={() => showModalSignIn(true)}
                                        className="common-btn sign-in-btn"
                                    >
                                        Sign in
                                    </Button>
                                </li>
                                <li className="list-inline-item">
                                    <Button
                                        onClick={() => showModalSignup(true)}
                                        className="common-btn signup-btn"
                                    >
                                        Sign up
                                    </Button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>);
};

export default CustomHeader;
