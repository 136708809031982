import axios from "axios";

export const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      config.headers.accessToken = token;
    }
    config.headers.Authorization =
      "Basic " + "M3JhZCQ5JSFAMyMhOjlyYSQ5JSFAMyEpJiMkQA==";
    // btoa(
    //   process.env.REACT_APP_USERNAME + " : " + process.env.REACT_APP_PASSWORD
    // );
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  function (error) {
    return Promise.reject(error.response);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const token = localStorage.getItem("accessToken");
    if (error.response?.status === 401 && token) {
      window.location.href = "/";
      localStorage.clear();
    }
    return Promise.reject(error.response);
  }
);
