import { Button, Modal } from "antd";
import "./modals.scss";

export default function Reject(props) {
  const { id, acceptorDeclineHandler } = props;
  return (
    <Modal
      centered
      width={576}
      header={null}
      footer={null}
      open={props.rejectModal}
      onCancel={props.onCloseRejected}
      onOk={props.onCloseRejected}
      className="common-modal decline-modal settings-service-modal"
      closable
      closeIcon={<img alt={""} src={"/assets/icons/close-icon.svg"} />}
    >
      <div className="row">
        <div className="col-12 crossImage common-div-modal text-center">
          <img alt={"img loading filed"} src={"/assets/icons/reject.png"} />
          <h3>Your account is Rejected</h3>
          <ul className="mb-0 list-inline">
            <li>
              <Button
                className="no-gradient button-common"
                onClick={props.onCloseRejected}
              >
                Done
              </Button>
            </li>
          </ul>
        </div>
      </div>
    </Modal>
  );
}
