import React, { useEffect } from "react";
import { DatePicker, Form, Input } from "antd";
import "../../../../styles/form.scss";

const BasicDetails = (props) => {
  const { profileData } = props;

  useEffect(() => {
    if (profileData?._id) {
      setTimeout(() => {
        props.form1Ref.setFieldValue("firstName", profileData?.firstName);
        props.form1Ref.setFieldValue("lastName", profileData?.lastName);
        props.form1Ref.setFieldValue("dob", profileData?.lastName);
      }, 300)
    }
  }, [profileData?._id]);

  const handleFormChange = () => {
    props.setDisabled(true)
    if (props.form.getFieldValue()) {
      props.setDisabled(false)
    }
    const hasErrors = !props.form.isFieldsTouched(true) || props.form.getFieldsError().filter(({ errors }) => errors.length).length > 0;
    props.setDisabled(hasErrors);
  }

  const validateAge = (_, value) => {
    if (!value) {
      return Promise.reject('Please select your birthdate');
    }

    const today = new Date();
    const selectedDate = new Date(value);
    const age = today.getFullYear() - selectedDate.getFullYear();

    if (age < 18) {
      return Promise.reject('You must be at least 18 years old');
    }

    return Promise.resolve();
  };

  return (
    <>
      <div>
        <div className="row">

          <div className="col-12 text-center steps-form-heading">
            <h5>Enter your basic details</h5>
          </div>
        </div>
        <div className="row scroll-div">
          <div className="col-12 common-form-div form-create-profile">
            <Form
              name="basic"
              initialValues={{ firstName: profileData?.firstName }}
              autoComplete="off"
              onFieldsChange={handleFormChange}
              form={props.form1Ref}
            >
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6">
                  <Form.Item
                    className="position-relative"
                    label={false}
                    name="firstName"
                    initialValue={profileData?.firstName}
                    rules={[
                      {
                        required: true,
                        message: 'Please input your first name!',
                      },
                      {
                        pattern: /^[a-zA-Z ]+$/, // Removed unnecessary `i` flag
                        message: "Invalid first name",
                      },
                    ]}
                  >
                    <Input placeholder="First name" onKeyPress={props.onlyTextHandler} onChange={handleFormChange} maxLength={15} />
                  </Form.Item>
                </div>
                <div className="col-12 col-sm-12 col-md-6">
                  <Form.Item
                    className="position-relative"
                    label={false}
                    name="lastName"
                    rules={[
                      {
                        required: true,
                        message: "Please input your last name!",
                      },
                      {
                        pattern: new RegExp(
                          /^[a-zA-Z ]+$/i
                        ),
                        message: "Invalid last name",
                      },
                    ]}
                  >
                    <Input placeholder="Last name" onKeyPress={props.onlyTextHandler} maxLength={15} onChange={handleFormChange} />
                  </Form.Item>
                </div>
                <Form.Item
                  className="position-relative"
                  placeholder="Enter your date of birth"
                  label={false}
                  name="dob"
                  rules={[
                    {
                      required: true,
                      validator: validateAge,
                    },
                  ]}
                >
                  <DatePicker
                    onChange={handleFormChange}
                    className="datePicker-dob"
                    format='MMMM D, YYYY'
                    allowClear={false}
                    showToday={false}
                    suffixIcon={<img className="img-fluid" alt={""} src="/assets/icons/calendar.svg"/>}
                    style={{ cursor: "pointer", height: '61px', width: '100%', background: '#f3f6f9', borderRadius: '70px', borderColor:' #f3f6f9',
                 }}
                    placeholder="Enter your date of birth" // Add this line
                  />
                </Form.Item>

              </div>
            </Form>
          </div >
        </div >
      </div>
    </>
  );
};

export default BasicDetails;
