import React from "react";
import { Form, Input, Button,message } from "antd";
import { axiosInstance } from "../../../controller/axiosInterceptor";
import { useNavigate,useSearchParams,Link } from "react-router-dom";

import "./forgotPassword.scss"

const ForgotPassword = () => {
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);
  const email = searchParams.get('email');

  const onFinish = (values) => {
    axiosInstance
      .post(`/business/forgetPassword?email=${email}`, values)
      .then((res) => {
        navigate("/");
        message.success("Password reset successfully");
      })
      .catch((err) => {
        console.error(err);
        message.error(err.data?.error?.message);
      });
  };

  const validateMessages = {
    required: "${label} is required",
  };

  return (
    <>
      <div className="col-9 col-sm-6 col-md-2 col-lg-2 col-xl-2 logo-div mt-4 px-3">
                        <Link to={" "}>
                            <img
                                src="/assets/images/logo-last-minute-app.png"
                                alt=""
                                className="img-fluid d-none d-sm-none d-md-block d-lg-block d-xl-block"
                            />
                            <img
                                src="/assets/icons/lma-logo-mobile.png"
                                alt=""
                                className="img-fluid  d-block d-sm-block d-md-none d-lg-none d-xl-none"
                            />
                        </Link>
                    </div>
        <div className="center-container">
      
      <div className="forgot-password-form">
        <h2 className="forgot-password-heading">Reset Password</h2>
        <Form
          name="forgotpassword"
          onFinish={onFinish}
          layout="vertical"
          validateMessages={validateMessages}
          className="forgot-password-form"
        >
          <Form.Item
            className="position-relative"
            name={"newPassword"}
            rules={[
              {
                required: true,
                message: "Please enter the New Password",
              },

              ({ getFieldValue }) => ({
                validator(_, value) {
                  const isContainsUppercase = /^(?=.*[A-Z]).*$/;
                  const isContainsNumber = /^(?=.*[0-9]).*$/;

                  const isContainsSymbol =
                    /^(?=.*[~`!@#$%^&*()--+={}|\\:;"'<>,.?/_₹]).*$/;

                  if (value?.length < 8) {
                    return Promise.reject(
                      new Error("Must contain minimum of 8 characters.")
                    );
                  }

                  if (
                    !isContainsNumber.test(value) ||
                    !isContainsSymbol.test(value) ||
                    !isContainsUppercase.test(value)
                  ) {
                    return Promise.reject(
                      new Error(
                        "Atleast one uppercase, lowercase, number(0-9), special character."
                      )
                    );
                  }

                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input.Password
              prefix={
                <div className="icon-beige-div">
                  <img
                    src="assets/icons/passwordIcon.svg"
                    alt="lock"
                    className="lock-icon"
                  />
                </div>
              }
              iconRender={(visible) =>
                visible ? (
                  <img
                    src="assets/icons/eye-open.svg"
                    alt="show"
                    className="eye-icon"
                    style={{ cursor: "pointer" }}
                  />
                ) : (
                  <img
                    src="assets/icons/eye-closed.svg"
                    alt="hide"
                    className="eye-icon"
                    style={{ cursor: "pointer" }}
                  />
                )
              }
              size="large"
              placeholder="Enter new Password"
              minLength={8}
              maxLength={12}
              className="input-fields"
              style={{ width: "335px", height: "70px" }}
            ></Input.Password>
          </Form.Item>
          <Form.Item
            name={"confirmPassword"}
            rules={[
              {
                required: true,
                message: "Please confirm the Password",
              },

              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(
                    new Error("Password should match with confirm password")
                  );
                },
              }),
            ]}
          >
            <Input.Password
              prefix={
                <div className="icon-beige-div">
                  <img
                    src="assets/icons/passwordIcon.svg"
                    alt="lock"
                    className="lock-icon"
                  />
                </div>
              }
              iconRender={(visible) =>
                visible ? (
                  <img
                    src="assets/icons/eye-open.svg"
                    alt="show"
                    className="eye-icon"
                    style={{ cursor: "pointer" }}
                  />
                ) : (
                  <img
                    src="assets/icons/eye-closed.svg"
                    alt="hide"
                    className="eye-icon"
                    style={{ cursor: "pointer" }}
                  />
                )
              }
              size="large"
              placeholder="Confirm new Password"
              minLength={8}
              maxLength={12}
              className="input-fields"
              style={{ width: "335px", height: "70px" }}
            ></Input.Password>
          </Form.Item>
          <div className="center-button btn-container">
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Save Changes
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </div>
    </>

  );
};

export default ForgotPassword;
