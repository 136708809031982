import { message } from "antd";
import {
  add,
  daysArr,
  formatDate,
  formatDateISO,
  formatLocalDate,
  generateNext90DatesFromSelectedDays,
  getDay,
  getUniqueDates,
  sortDates,
} from "../../../utils";
import dayjs from "dayjs";

export const getSelectedDate = (date) => {
  let startD = formatDateISO(date);
  return startD;
};

const getSelectedDaysNumber = (selectedDay) => {
  const days = selectedDay.map((day) => daysArr.indexOf(day));
  return days;
}

export const getStartEndDate = (date, startTime, endTime) => {
  const startHours = new Date(startTime).getHours();
  const startMinutes = new Date(startTime).getMinutes();
  const endHours = new Date(endTime).getHours();
  const endMinutes = new Date(endTime).getMinutes();

  let startD = new Date(date).setHours(startHours, startMinutes, 0);
  startD = formatDateISO(startD);
  let endD = new Date(date).setHours(endHours, endMinutes, 0);
  endD = formatDateISO(endD);
  return { startD, endD };
};

export const getPreselectAvailability = (
  selectedDay,
  selectedDate,
  presSelected,
  availabilityDetails
) => {
  let prevData = [];
  if (selectedDay.length > 0) {
    const daysArr = getSelectedDaysNumber(selectedDay);
    prevData = presSelected?.filter((selected) => {
      const preselected = availabilityDetails.find(
        (item) => item.date === selected.date
      );

      const dateNumber = new Date(selected.date).getDay();

      const dayPresetInWeekDays = daysArr.includes(dateNumber);

      console.log(preselected, "preselected", selected.date, selectedDay);
      if (new Date(selected.date) < new Date(selectedDate) && dayPresetInWeekDays) return true;
      else if (!preselected && dayPresetInWeekDays) return true;
      else return false;
    });
  } else {
    prevData = presSelected?.filter(
      (selected) => selected.date !== selectedDate
    );
  }

  const prevList = prevData?.length
    ? prevData.map((it) => {
        return {
          date: it.date,
          startTime: it.startTime,
          endTime: it.endTime,
        };
      })
    : [];

  return prevList;
};

export const getNewAvailability = (
  isEdit,
  startTime,
  endTime,
  selectedDay,
  selectedDate,
  presSelected
) => {
  const dateSelected = `${daysArr[getDay(selectedDate)]} ${formatDate(
    selectedDate,
    "MMMM DD YYYY"
  )}`;

  let allDates = [];

  if (isEdit) {
    const startDate = presSelected[0]?.startTime;

    allDates =
      selectedDay.length > 0
        ? generateNext90DatesFromSelectedDays(
            selectedDay,
            dateSelected,
            startDate
          )
        : [selectedDate];
  } else {
    allDates =
      selectedDay.length > 0
        ? generateNext90DatesFromSelectedDays(selectedDay, dateSelected)
        : [selectedDate];
  }

  const availabilityDetails = allDates.map((dateslted) => {
    const date = formatLocalDate(dateslted);
    return {
      date: formatDateISO(date),
      startTime: getStartEndDate(dateslted, startTime, endTime).startD,
      endTime: getStartEndDate(dateslted, startTime, endTime).endD,
    };
  });

  return availabilityDetails;
};

export const getPayload = (
  startTime,
  endTime,
  isEdit,
  selectedDate,
  selectedDay,
  presSelected
) => {
  if (endTime < startTime) {
    message.error("End time can't be less than start time.");
    return;
  }
  if (isEdit) {
    const availabilityDetails = getNewAvailability(
      isEdit,
      startTime,
      endTime,
      selectedDay,
      selectedDate,
      presSelected
    );

    // find preselect data which is not conflicting with new availability
    let prevList = getPreselectAvailability(
      selectedDay,
      selectedDate,
      presSelected,
      availabilityDetails
    );

    const listUpdated = [...availabilityDetails, ...prevList];

    const uniqueList = getUniqueDates(listUpdated);
    const sortedList = sortDates(uniqueList);

    return { availabilityDetails, sortedList };
  } else {
    const availabilityDetails = getNewAvailability(
      isEdit,
      startTime,
      endTime,
      selectedDay,
      selectedDate,
      presSelected
    );

    // select selectedDate if selected date is not started in repeat on
    const dateS = new Date(selectedDate).getDay();
    const selectSelectedDate = [];
    if (selectedDay.indexOf(daysArr[dateS]) === -1) {
      selectSelectedDate.push({
        date: selectedDate,
        startTime: getStartEndDate(selectedDate, startTime, endTime).startD,
        endTime: getStartEndDate(selectedDate, startTime, endTime).endD,
      });
    }

    const selectedD = selectSelectedDate.length
      ? availabilityDetails.find(
          (item) => new Date(item.date) === new Date(selectSelectedDate[0].date)
        )
      : [];

    // find preselect data which is not conflicting with new availability
    const prevList = getPreselectAvailability(
      selectedDay,
      selectedDate,
      presSelected,
      availabilityDetails
    );

    const listUpdated = [
      ...availabilityDetails,
      ...(selectedD ? [] : [...selectSelectedDate]),
      ...prevList,
    ];

    const uniqueList = getUniqueDates(listUpdated);
    const sortedList = sortDates(uniqueList);

    return { availabilityDetails, sortedList };
  }
};

const handlePreSelectDays = (list, setSelectedDay) => {
  const startD = list[0].startTime;
  const dateAfterSeven = add(dayjs(startD), 7, "day");
  const dateAfterFourteen = add(dayjs(startD), 14, "day");
  const days = [1, 2, 3, 4, 5, 6, 7].map((day) => {
    if (
      formatDate(dateAfterSeven, "HH") ==
      formatDate(add(dateAfterSeven, day, "day"), "HH")
    )
      return formatDate(add(dateAfterSeven, day, "day"), "dddd");
  });

  const filterList = list.filter((date, ind) => {
    if (
      dayjs(date.startTime) > dateAfterSeven &&
      dayjs(date.startTime) <= dateAfterFourteen &&
      ind !== 0
    ) {
      return (
        formatDate(dateAfterSeven, "HH") === formatDate(date.startTime, "HH")
      );
    } else return false;
  });

  // const dayList = filterList.map((item) => formatDate(item.date, "dddd"));
  setSelectedDay([]);
};
