import Spin from 'antd/es/spin'

const SpinWrapper = ({ children, loader }) => {
  return (
    <Spin spinning={loader} style={{ height: '100%', marginTop: '3rem' }} >
      {children}
    </Spin>
  )
}

export default SpinWrapper;