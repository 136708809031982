import "../layout.scss";
import { Button } from "antd";
import { useState } from "react";
import Drawer from "../../drawer";
import TermsAndConditionsContent from "../../modals/auths/TermsAndConditionsContent";
import PrivacyPolicyContent from "../../modals/auths/PrivacyPolicyContent";

export default function Footer(props) {
  const [termsConditionVisible, setTermsConditionVisible] = useState();
  const [privacyPolicyVisible, setPrivacyPolicyVisible] = useState();

  const showTermsConditionsModal = (visible) => {
    setTermsConditionVisible(visible);
  };

  const showPrivacyPolicyModal = (visible) => {
    setPrivacyPolicyVisible(visible);
  };

  return (
    <>
      <div
        className={
          props.from
            ? "footer-main-setting footer-main"
            : "footer-main center-items"
        }
      >
        <ul className="list-inline mb-0">
          <li className="list-inline-item">
            <Button
              onClick={() => showTermsConditionsModal(true)}
              className="bg-transparent border-0 p-0 shadow-none h-auto"
            >
              Terms & Conditions
            </Button>
          </li>
          <li className="list-inline-item">|</li>
          <li className="list-inline-item">
            <Button
              onClick={() => showPrivacyPolicyModal(true)}
              className="bg-transparent border-0 p-0 shadow-none h-auto"
            >
              Privacy Policy
            </Button>
          </li>
        </ul>
      </div>
      <Drawer show={termsConditionVisible} onClose={() => showTermsConditionsModal(false)}>
        <TermsAndConditionsContent slider />
      </Drawer>
      <Drawer show={privacyPolicyVisible} onClose={() => showPrivacyPolicyModal(false)}>
        <PrivacyPolicyContent slider />
      </Drawer>
    </>
  );
}
